import React from 'react';
import './CanalesVenta.css'; // Asegúrate de importar tu archivo CSS

export default function CanalesVenta() {
  const canalesVenta = {
    "Distribuidores": [
      {
        nombre: 'Food Service de México',
        enlace: 'https://www.foodservice.com.mx/',
        imagen: '/media/foodservice.jpg',
      },
      {
        nombre: 'Grupo Sigma',
        enlace: 'https://clientes.sigmafoodservice.com/sigma/es/m/unilever?tracking_template=lp-tiendas&tracking_topic=unilever&tracking_element=banner-marca&tracking_position=12',
        imagen: '/media/gruposigma.jpg',
      },
      {
        nombre: 'All Serve Food Service',
        enlace: 'https://www.allservefoodservice.com/',
        imagen: '/media/allserve.jpg',
      },
      {
        nombre: 'Distribuidora de Alimentos, Hoteles y Restaurantes',
        enlace: 'https://www.laranitadelapaz.com.mx/',
        imagen: '/media/laranita.jpg',
      },
      {
        nombre: 'Abarrotes Raúl',
        enlace: 'https://www.pidefacilraul.com/',
        imagen: '/media/abarrotesraul.jpg',
      },
    ],
    "Ecommerce": [
      {
        nombre: 'Amazon',
        enlace: 'https://www.amazon.com.mx/foodsolutions',
        imagen: '/media/amazon.jpg',
      },
      {
        nombre: 'Sam’s Club',
        enlace: 'https://www.sams.com.mx/c/unilever/para-tus-comidas/cat1410025',
        imagen: '/media/samsclub.jpg',
      },
    ],
    "Mayoreo": [
      {
        nombre: 'Prim',
        enlace: 'https://www.abastodeli.com/',
        imagen: '/media/prim.jpg',
      },
      {
        nombre: 'Productos de conzumo Z',
        enlace: 'https://zproductos.com/',
        imagen: '/media/productosz.jpg',
      },
      {
        nombre: 'Sahuayo',
        enlace: 'https://www.sahuayo.com.mx/',
        imagen: '/media/sahuayo.jpg',
      },
    ],
  };

  return (
    <div id='venta' className="row">
      <div style={{ marginTop: '100px' }} className="col-12 text-center mb-4">
        <div style={{ textAlign: '-webkit-center' }} className='pb-4'>
          <h2 style={{ background: '#cbc5c7', width: '400px', padding: '10px', borderRadius: '50px' }}>Canales de Venta</h2>
        </div>
        <div className="container">
          {Object.keys(canalesVenta).map((categoria, index) => (
            <div key={index} className="mb-5">
              <h3 className="categoria-titulo">{categoria}</h3>
              <div className="row">
                {canalesVenta[categoria].map((canal, i) => (
                  <div key={i} className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100 custom-card">
                      <div className="image-container">
                        <img src={canal.imagen} alt={canal.nombre} />
                      </div>
                      <div className="card-body custom-card-body">
                        <h5 className="card-title custom-card-title">{canal.nombre}</h5>
                        <a href={canal.enlace} target="_blank" rel="noopener noreferrer" className="btn custom-btn">
                          Visitar
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
