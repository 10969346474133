import React from 'react'
import './Esenciales.css';
import { Link } from 'react-router-dom';

export default function Esenciales() {
  return (
    <div id='esenciales' className="row">
        <div className="col-12 text-center esenciales-bg pt-4 pb-4">
            <div className='subtitulo'>
              <h2 className='prod-part' style={{ background: '#cbc5c7', padding: '10px', borderRadius: '50px'  }}>Productos participantes</h2>
            </div>
            <div className="row">
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e1.png" alt="Descripción de la imagen" />
                <a href='https://www.amazon.com.mx/Knorr-Professional-Caldo-Pollo-1-85/dp/B07R8KZQG9/ref=sr_1_24_sspa?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD%2BSOLUTIONS&qid=1723589341&sr=8-24-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&th=1' target="_blank"><button>Compra</button></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e2.png" alt="Descripción de la imagen" />
                <a href='https://www.amazon.com.mx/KNORR-Pure-de-Papa-800G/dp/B087W3F5JR/ref=sr_1_9?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-9' target="_blank"><button>Compra</button></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e3.png" alt="Descripción de la imagen" />
                <a href='https://www.amazon.com.mx/Knorr%C2%AE-Condimix-Pollo-SOLUTIONS-UNILEVER/dp/B0BMJY425F/ref=sr_1_5?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-5' target="_blank"><button>Compra</button></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e4.png" alt="Descripción de la imagen" />
                <a href='https://www.amazon.com.mx/Hellmanns-HELLMANNS-Mayonesa-Real-litros/dp/B08KSFXH49/ref=sr_1_3?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-3&ufe=app_do%3Aamzn1.fos.242f5c11-6cfd-40d6-91f6-be3d1974080c' target="_blank"><button>Compra</button></a>
              </div>
            </div>
        </div>
    </div>
  )
}
