import React, { useState, useEffect } from 'react';
import Banner from './Banner';
import Premios from './Premios';
import Jurado from './Jurado';
import Productos from './Productos';
import QuienesSomos from './QuienesSomos';
import Formulario from './Formulario';
import CanalesVenta from './CanalesVenta';
import Esenciales from './Esenciales';
import Popup from './Popup';

export default function Home() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Mostrar el popup automáticamente al cargar la página
  useEffect(() => {
    setIsPopupVisible(true);
  }, []);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <section id='banner'>
        <Banner />
      </section>
      <section id='esenciales'>
        <Esenciales />
      </section>
      <section id='premios'>
        <Premios />
      </section>
      <section id='jurado'>
        <Jurado />
      </section>
      <section id='venta'>
        <CanalesVenta />
      </section>
      <section id='productos'>
        <Productos />
      </section>
      <section id='quienes'>
        <QuienesSomos />
      </section>

    </>
  );
}
