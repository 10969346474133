import React from 'react';
import './Footer.css'; // Asegúrate de crear y vincular un archivo CSS para el estilo

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img src="/media/Logo.png" alt="Logo" />
      </div>
      <div className="footer-social">
        <a href="https://www.instagram.com/foodsolutionsmx/" target="_blank" rel="noopener noreferrer">
          <img src="/media/Ins.png" alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/UnileverFSMx" target="_blank" rel="noopener noreferrer">
          <img src="/media/Fac.png" alt="YouTube" />
        </a>
        <a href="https://www.youtube.com/@UFSMx" target="_blank" rel="noopener noreferrer">
          <img src="/media/You.png" alt="YouTube" />
        </a>
      </div>
      <div className="footer-legal">
        <a href="https://www.unilevernotices.com/privacy-notices/mexico-spanish.html" target="_blank">Aviso de privacidad</a> | <a href="/terminos-condiciones" target="_blank">Términos y condiciones</a>
      </div>
    </footer>
  );
}
