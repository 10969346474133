import React from 'react'

export default function QuienesSomos() {
  return (
    <div id='quienes' className="row">
        <div className="col-12 text-center mb-4 mt-4">
          <div style={{ textAlign: '-webkit-center'}} className='pb-4'>
            <h2 style={{ background: '#cbc5c7', width: '400px', padding: '10px', borderRadius: '50px'  }}>¿Quiénes Somos?</h2>            
          </div>
          <div>
            <iframe width="80%" height="560" src="https://www.youtube.com/embed/3-4-ovEBjCM?si=LR3a2XEsKc1tf2Ue" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
    </div>
  )
}
