import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './page/Home';
import Dashboard from './page/Dashboard'; // Asegúrate de importar Dashboard
import Terminos from './page/Terminos';
import './App.css';
import './assets/fonts/fonts.css';

export default function App() {
  return (
    <Router>
      <div className='app'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/terminos-condiciones" element={<Terminos /> } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
