
import React from 'react'
import './Esenciales.css';
import { Link } from 'react-router-dom';

export default function Esenciales() {
  return (
    <div id='esenciales' className="row">
        <div className="col-12 text-center">
          <Link to="/">
            <img className='banner-p' src="/media/banner.jpg" alt="Descripción de la imagen" />
          </Link>
        </div>
    </div>
  )
}
