import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Premios.css';

export default function Premios() {
  return (
    <div id='premios'>
      <div className="col-12 text-center mt-4">
        <div className='premios-bg'>
          <div className='subtitulo pt-4 mt-4'>
            <h2 style={{ background: '#cbc5c7', width: '300px', padding: '10px', borderRadius: '50px'  }}>Premios</h2>
          </div>
          
          {/* Carrusel que solo se mostrará en móviles */}
          <div className="premios-carousel">
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100 premios" src="/media/1ro.jpg" alt="Descripción de la imagen 1" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100 premios" src="/media/2do.jpg" alt="Descripción de la imagen 2" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100 premios" src="/media/3ro.jpg" alt="Descripción de la imagen 3" />
              </Carousel.Item>
            </Carousel>
          </div>

          {/* Fila de imágenes que se mostrará en escritorio */}
          <div className="row premios-row">
            <div className="col-4">
              <img className='premios' src="/media/1ro.jpg" alt="Descripción de la imagen" />
            </div>
            <div className="col-4">
              <img className='premios' src="/media/2do.jpg" alt="Descripción de la imagen" />
            </div>
            <div className="col-4">
              <img className='premios' src="/media/3ro.jpg" alt="Descripción de la imagen" />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
