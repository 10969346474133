import React, { useState, useEffect } from 'react';
import './Dashboard.css';

export default function Dashboard() {
  const [form1Data, setForm1Data] = useState([]);
  const [form2Data, setForm2Data] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'Un1L3veR13$';  // Contraseña correcta Un1L3veR13$

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setAuthenticated(true);
    } else {
      alert('Contraseña incorrecta');
    }
  };

  useEffect(() => {
    if (authenticated) {
      const fetchData = async () => {
        try {
          // Asegúrate de que estas rutas coincidan con tu backend
          const form1Response = await fetch('https://saborrenovadoufs.com.mx/api/first');
          const form1Results = await form1Response.json();
          setForm1Data(form1Results);

          const form2Response = await fetch('https://saborrenovadoufs.com.mx/api/second');
          const form2Results = await form2Response.json();
          setForm2Data(form2Results);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [authenticated]);

  const handleDownloadCSV = (data, filename) => {
    const csv = [
      Object.keys(data[0]).join(','), // Header
      ...data.map(row => Object.values(row).join(',')) // Data
    ].join('\n');

    const csvBlob = new Blob([csv], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(csvUrl);
  };

  const handleDownloadVideo = (videoFilename) => {
    const videoUrl = `https://saborrenovadoufs.com.mx/videos/${videoFilename}`;
    const link = document.createElement('a');
    link.href = videoUrl;
    link.download = videoFilename;
    link.click();
  };

  if (!authenticated) {
    return (
      <div className="auth-container">
        <h1>Acceso al Dashboard</h1>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit">Entrar</button>
        </form>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="download-buttons">
        <button style={{ margin: '0px 10px'}} onClick={() => handleDownloadCSV(form1Data, 'form1_data.csv')}>
          Descargar CSV Formulario 1
        </button>
        <button style={{ margin: '0px 10px'}} onClick={() => handleDownloadCSV(form2Data, 'form2_data.csv')}>
          Descargar CSV Formulario 2
        </button>
      </div>
      <div className="table-container">
        <h2>Datos del Formulario 1</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>RFC</th>
              <th>Estado</th>
              <th>Ciudad</th>
              <th>Código Postal</th>
              <th>Tipo de Negocio</th>
              <th>Video</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {form1Data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.lastname}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>{item.rfc}</td>
                <td>{item.state}</td>
                <td>{item.city}</td>
                <td>{item.postalCode}</td>
                <td>{item.businessType}</td>
                <td>
                {item.video && (
                  <a href={`https://saborrenovadoufs.com.mx/videos/${item.video}`} download>
                    Descargar Video
                  </a>
                )}
                </td>
                <td>{new Date(item.date).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-container">
        <h2>Datos del Formulario 2</h2>
        <table className="data-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>Estado</th>
              <th>Ciudad</th>
              <th>Código Postal</th>
              <th>Nombre del Negocio</th>
              <th>Tipo de Negocio</th>
              <th>Gastos Mensuales</th>
              <th>Fuente de Insumos</th>
              <th>Video</th>
            </tr>
          </thead>
          <tbody>
            {form2Data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.lastname}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>{item.state}</td>
                <td>{item.city}</td>
                <td>{item.postalCode}</td>                
                <td>{item.businessName}</td>
                <td>{item.businessType}</td>
                <td>{item.monthlyExpenses}</td>
                <td>{item.supplySource}</td>
                <td>
                {item.video && (
                  <a href={`https://saborrenovadoufs.com.mx/videos/${item.video}`} download>
                    Descargar Video
                  </a>
                )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
