import React from 'react'
import './Terminos.css'

export default function Terminos() {
  return (
    <div className='terminos'>
        <img src="/media/T01.jpg" alt="" />
        <img src="/media/T02.jpg" alt="" />
        <img src="/media/T03.jpg" alt="" />
        <img src="/media/T04.jpg" alt="" />
        <img src="/media/T05.jpg" alt="" />
        <img src="/media/T06.jpg" alt="" />
        <img src="/media/T07.jpg" alt="" />
        <img src="/media/T08.jpg" alt="" />
        <img src="/media/T09.jpg" alt="" />
        <img src="/media/T10.jpg" alt="" />
    </div>
  )
}
