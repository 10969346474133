import React from 'react'
import './Jurado.css'

export default function Jurado() {
  return (
    <div id='jurado' className="row">
        <div className="col-12 text-center mb-4 mt-4">
            <div className='subtitulo'>
              <h2 style={{ background: '#cbc5c7', width: '300px', padding: '10px', borderRadius: '50px'  }}>Jurado</h2>
            </div>
            <div className="row">
              <div className="col-6 col-md-3">
                <img className='jurado' src="/media/Carlos Venegas.png" alt="Descripción de la imagen" />
                <h2 style={{ fontFamily: 'ChefsHand', marginBottom: '10px' }}>Chef Carlos Venegas</h2>
                <a href='https://www.instagram.com/chefcarlosvenegas/?hl=es' target='_blank'><p>IG @chefcarlosvenegas</p></a>
                <a href='https://www.tiktok.com/@chefcarlosvenegas' target='_blank'><p>TikTok @chefcarlosvenegas</p></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='jurado' src="/media/Regina Logar.png" alt="Descripción de la imagen" />
                <h2 style={{ fontFamily: 'ChefsHand',  marginBottom: '10px' }}>Chef Regina Logar</h2>
                <a href='https://www.instagram.com/reginalogar/?hl=es' target='_blank'><p>IG @reginalogar</p></a>
                <a href='https://www.tiktok.com/@reginalogar' target='_blank'><p>TikTok @reginalogar</p></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='jurado' src="/media/Andres Cardenas.png" alt="Descripción de la imagen" />
                <h2 style={{ fontFamily: 'ChefsHand', marginBottom: '10px' }}>Chef Andrés Cárdenas</h2>
                <a href='https://www.instagram.com/chefandrescardenas/?hl=es' target='_blank'><p>IG @chefandrescardenas</p></a>
                <a href='https://www.tiktok.com/@chefandrescardenas' target='_blank'><p>TikTok @chefandrescardenas</p></a>
              </div>
              <div className="col-6 col-md-3">
                <img className='jurado' src="/media/Saul Schwarz.png" alt="Descripción de la imagen" />
                <h2 style={{ fontFamily: 'ChefsHand', marginBottom: '10px' }}>Chef Saúl Schwarz</h2>
                <a href='https://www.instagram.com/chefschwarz/?hl=es' target='_blank'><p>IG @chefschwarz</p></a>
                <a href='https://www.tiktok.com/@chefschwarz' target='_blank'><p>TikTok @chefschwarz</p></a>
              </div>
            </div>
        </div>
    </div>
  )
}
