import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Productos.css'

export default function Productos() {
  const productos = [
    {
      imagen: '/media/Render MX-Cheddar 1.png',
      link: 'https://www.amazon.com.mx/Hellmanns-HELLMANNS-Aderezo-Cheddar-litros/dp/B08KSN5M1W/ref=sr_1_4?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-4'
    },
    {
      imagen: '/media/REAL_1_7.png',
      link: 'https://www.amazon.com.mx/Hellmanns-HELLMANNS-Mayonesa-Real-litros/dp/B08KSFXH49/ref=sr_1_3?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-3&ufe=app_do%3Aamzn1.fos.242f5c11-6cfd-40d6-91f6-be3d1974080c'
    },
    {
      imagen: '/media/Condimix3,6.png',
      link: 'https://www.amazon.com.mx/Knorr%C2%AE-Condimix-Pollo-SOLUTIONS-UNILEVER/dp/B0BMJY425F/ref=sr_1_5?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-5'
    },
    {
      imagen: '/media/knorr1,85.png',
      link: 'https://www.amazon.com.mx/Knorr-Professional-Caldo-Pollo-1-85/dp/B07R8KZQG9/ref=sr_1_24_sspa?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD%2BSOLUTIONS&qid=1723589341&sr=8-24-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9tdGY&th=1'
    },
    {
      imagen: '/media/PurePapa800g.png',
      link: 'https://www.amazon.com.mx/KNORR-Pure-de-Papa-800G/dp/B087W3F5JR/ref=sr_1_9?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-9'
    },
    {
      imagen: '/media/Hellmann_s3,7kg.png',
      link: 'https://www.amazon.com.mx/Hellmanns-S05RM-HELLMANNS-Aderezo-Cesar/dp/B08JXMZJMB/ref=sr_1_17?dib=eyJ2IjoiMSJ9.pA3JNx_dI7i-j-E9NF0P5LWJUG6N1HuPmTwjgtci6xJa3i4jgxLzSFIrcXm5lULGWbHFm1Mpmc1unoe1wsqlmgplWFVkQFND9_gCzCoEOQLxZVM8FewuZdV3BXTMS_gc4dhXLv8jrlOM2DYAQIV0wtnl2nNC6nZ4cIqn92o7HeqIM8-TFsnKRIwkMPH_63BUR8H8bjZ_T6xuoGt9OwpqZYPazrEVQt77Xicmx6eWwOhZqd88HWSy1dZEtFkWMN9Ptq-4KQktsksZmkkGpH2xnXkPGm3KSHMvyHvDEYOrL0U.8Q8xtX1pRRXrP9MWPsW3HfGZ7CpCnDrpKxZTdOVdmtc&dib_tag=se&keywords=FOOD+SOLUTIONS&qid=1723589341&sr=8-17'
    }
  ];

  
  const groupedProducts = [];
  for (let i = 0; i < productos.length; i += 3) {
    groupedProducts.push(productos.slice(i, i + 3));
  }

  return (
    <div id='productos' className="row">
      <div className="col-12 text-center mb-4 mt-4">
        <div style={{ textAlign: '-webkit-center'}} className='pb-4'>
          <h2 style={{ background: '#cbc5c7', width: '400px', padding: '10px', borderRadius: '50px'  }}>¡Consíguelos aquí!</h2>            
        </div>
      </div>
      <div className="col-12">
        <Carousel indicators={true} interval={null} controls={true} className="custom-carousel">
          {groupedProducts.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-center">
                {group.map((producto, i) => (
                  <div key={i} className="col-4">
                    <div className="card-p">
                      <a href={producto.link} target="_blank" rel="noopener noreferrer">
                        <img
                          className="d-block w-100 product-image"
                          src={producto.imagen}
                          alt={`Producto ${i + 1}`}
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}