import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import './Header.css';

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <nav className={`nav ${isNavOpen ? 'open' : ''}`}>
        <HashLink smooth to="/#formulario"><span>Concurso</span></HashLink>
        <HashLink smooth to="/#premios"><span>Premios</span></HashLink>
        <HashLink smooth to="/#jurado"><span>Jurado</span></HashLink>
        <HashLink smooth to="/#venta"><span>Canales de Venta</span></HashLink>
        <HashLink smooth to="/#productos"><span>Productos</span></HashLink>
        <HashLink smooth to="/#quienes"><span>Quienes Somos</span></HashLink>
      </nav>
      <button className="hamburger" onClick={toggleNav}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
    </header>
  );
}
